import "./Navbar.css";


import {Link} from "react-router-dom"


export default function Navbar(){
    return(
        
                
                <nav>
                    
                    <Link to="/"><a href="#grocery" className="logo">G L O B A L - D E L I</a></Link>
                    <input type="checkbox" id="click" />
                        <label for="click" className="menu-btn">
                            <i class="fas fa-bars"></i>
                        </label>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><a href="#shop">Shop</a></li>
                        <li><Link to="/about">About</Link></li>
                        <li><a href="#contact">Contact</a></li>
                                    
                    </ul>
                    
                </nav>
    );
}
