import Navbar from "../components/Navbar/Navbar"
import About from "../pages/About"
import Container from "../components/Container/Container"
import Grid_Items from "../components/Grid-Items/Grid-Items"
import Info from "../components/Info/Info"
import Icons from "../components/Icons/Icons"
import Map from "../components/Map/Map"



export default function Home(){
    return(
        <>
        <Navbar />
            
        

        
        <Container />
        <Grid_Items />
        <Info />
        
        <Icons />
        </>
    )
}