import "./SeventhModal.css"

import C7 from "../Grid-Items/g7.jpg"

export default function SeventhModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_7-overlay"></div>
            <div className="modal_7">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_7-info">
                    <img src={C7} alt="" />
                    <p id="big"><h2>Beauty and Hygiene</h2><br />

                    
                    We got you covered with the best selling beauty and wellness brands. Bodywashes, creams, sanitary napkins, makeup products and everything you need! <br /><br />
                    Price as per MRP</p>
                    
                    

                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}