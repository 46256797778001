
import React from 'react';
import './App.css';
import Home from "./pages/Home"
import About from './pages/About';
import{Routes,Route} from "react-router-dom"

function App() {
  return (
    <>
      <body>
      
        {/* <Home /> */}
        
        <Routes>
        <Route path="/"
        element={<Home/>} />
        <Route exact path="/about"  element={<About/>} />
        <Route path="/"
        element={<Home/>} />
        </Routes>
  
      </body>

      
    </>







    
  );
}

export default App;
