import "../Info/Info.css";

export default function Info(){
    return(

        <>
        <div className="info-container" id="contact">
            <div className="info-content">
                <div className="left-side">
                    <div className="details">
                        <h4>GLOBAL-DELI</h4>
                        <br />
                        <br />
                        <div className="row-1">
                            <div className="col-1">
                                <p id="first">Pune, Maharashtra<br />

                                    T:  +91 9420133283<br />

                                    </p>
                            </div>
                            <br />
                            <br />
                            <div className="col-1">
                                <p id="first">MON - FRI:  7am - 10pm<br />

                                    SATURDAY:   8am - 10pm<br />

                                    SUNDAY:   8am - 11pm</p>
                            </div>    
                            <br />
                            <br />
                            <div className="col-1">
                                {/* <p id="first">FAQ / Shipping & Returns /<br />
                                    Store Policy /<br />
                                    Payment Methods</p> */}
                            </div>
                        </div>

                        <div className="row-2">
                            <div className="col-2">
                                <p >Pune, Maharashtra <br />
                                    T:  +91 9420133283
                                </p>
                            </div>
                            {/* <br />
                            <br /> */}
                            <div className="col-2">
                                <p>MON - FRI:  7am - 10pm<br />

                                    SATURDAY:   8am - 10pm<br />

                                    SUNDAY:   8am - 11pm</p>
                            </div>    
                            {/* <br />
                            <br /> */}
                            <div className="col-2">
                                {/* <p>FAQ / Shipping & Returns /<br />
                                    Store Policy /<br />
                                    Payment Methods</p> */}
                            </div>
                        </div>
                        
                        
                        
                    </div>
                </div>

                
            
        

            
                <div className="right-side">
                    

                    <div className="social-link">
                    <h2>Connect With Us on <a href="https://wa.me/919370384519">Whatsapp</a></h2>
                    </div>
                    {/* <a href=""><i class="fa-brands fa-whatsapp"></i></a> */}
                </div>
            </div>
        </div>
        
        </>
        
    )
}