import "./ForthModal.css"

import C4 from "../Grid-Items/g4.jpg"

export default function ForthModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_4-overlay"></div>
            <div className="modal_4">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_4-info">
                    <img src={C4} alt="" />
                    <p id="big"><h2>Snacks and Branded Foods</h2><br />

                    
                    Craving readymade snacks and foods? We are here to help you get them. Our store has all kinds of readymade foods which serve right to your needs. <br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}