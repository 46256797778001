import "./NinthModal.css"

import C9 from "../Grid-Items/g9.jpg"

export default function NinthModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_9-overlay"></div>
            <div className="modal_9">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_9-info">
                    <img src={C9} alt="" />
                    <p id="big"><h2>Fresh fruits and vegetables</h2><br />

                    
                    We are health conscious and so should you be. All the health supplements and fresh produces right from the barns for you. <br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}