import Navbar from "../components/Navbar/Navbar";
import "./About.css"
import Man from "../components/Grid-Items/shop.jpg";
import Info from "../components/Info/Info"



export default function About(){
    return(
        <>

        
            <Navbar />
            <div className="about">
                    <h4>ABOUT US</h4>
                    <p>This is a grocery store operating in Pune with deliveries all across the city. We offer a wide range of food products for all your everyday needs and even offer grocery home delivery. Our grocery store has fresh fruits and vegetables, a wide range of breads and other packaged bakery products as well as a range of fresh cheeses from around the world. 

                    <br /><br />
                    

                    We even have a range of every staple and international cuisine product to meet your needs along with organic products for fresh and packaged food items. With our offerings, we have the best online food shop & grocery shopping platform available for all your grocery products as well as physical grocery stores in the city. </p>
            </div>
            <div className="about-img">
                <img src={Man}alt="" />
            </div>
            <Info />
        </>
    )
}

