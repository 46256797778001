import "./SecondModal.css"

import C2 from "../Grid-Items/g22.jpg"

export default function SecondModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_2-overlay"></div>
            <div className="modal_2">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_2-info">
                    <img src={C2} alt="" />
                    <p id="big"><h2>Dairy Products</h2><br />

                    
                    From Chitale to Haldiram's, we have all kinds of dairy products available. Milk, Curd, Butter, Cheese and a lot more. <br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}