import Crockery from './crockery.jpg';
import "../Container/Container.css";
import Grocery from '../Container/grocery.jpg'

export default function Container(){
    return(
        <>
            
                <div className="container" id="grocery">
                    <img src={Grocery} id="banner" alt="" />
                    <div className="centered">
                        <h2>GLOBAL DELI</h2>
                        <p>By Alok Kulkarni</p>
                        <a href="#shop"><button id="button-clr" className="button-shop-now" type="button">Shop Now</button></a>
                    </div>
                    
                </div>
                <div className="collection">
                    <h4>ONLY THE FRESH</h4>
                    <p>Let's work together to celebrate local flavor and ensure grocery shopping is an experience, not a chore. The right design enhances your brand by creating unique experiences and bringing your mission and values to life.</p>
                </div>
            
        </>
    )
}