import "../Icons/Icons.css";
import {Link} from "react-router-dom"

export default function Icons(){
    return(
       <>
            
                
            
            <div className="icon-container">
                <div className="social-icons">
                    <Link><i className="fa-brands fa-facebook-f"></i></Link>
                    <Link ><i className="fa-brands fa-instagram"></i></Link>
                    
                    
                </div>
            </div>
            
       </> 
    )
}