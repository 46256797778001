import "./FifthModal.css"

import C5 from "../Grid-Items/g5.jpg"

export default function FifthModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_5-overlay"></div>
            <div className="modal_5">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_5-info">
                    <img src={C5} alt="" />
                    <p id="big"><h2>Foodgrains and Oil</h2><br />

                    
                    We bring the best quality rice, dal, wheat and a plethora of other grains for you. Get all the nutrients right to have a perfect meal.<br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}