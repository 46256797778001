import "./SixthModal.css";

import C6 from "../Grid-Items/g6.jpg";

export default function SixthModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_6-overlay"></div>
            <div className="modal_6">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_6-info">
                    <img src={C6} alt="" />
                    <p id="big"><h2>Spices and Mixes</h2><br />

                    
                    Mirchi powder, Haldi, garam masala and what not. We have all the spices for a perfect indian dish! <br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}