import React, { useState } from "react";

import "../Grid-Items/Grid-Items.css";

import C1 from "../Grid-Items/g1.jpg";
import C2 from "../Grid-Items/g22.jpg";
import C3 from "../Grid-Items/g3.jpg";
import C4 from "../Grid-Items/g4.jpg";
import C5 from "../Grid-Items/g5.jpg";
import C6 from "../Grid-Items/g6.jpg";
import C7 from "../Grid-Items/g7.jpg";
import C8 from "../Grid-Items/g8.jpg";
import C9 from "../Grid-Items/g9.jpg";
import FirstModal from "../Modal/FirstModal"
import SecondModal from "../Modal/SecondModal"
import ThirdModal from "../Modal/ThirdModal"
import ForthModal from "../Modal/ForthModal";
import FifthModal from "../Modal/FifthModal";
import SixthModal from "../Modal/SixthModal";
import SeventhModal from "../Modal/SeventhModal";
import EightModal from "../Modal/EightModal";
import NinthModal from "../Modal/NinthModal";

// const MODAL_IMG = {
//     height: 270,
    
// }

export default function Grid_Items(){
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);
    const [isOpen8, setIsOpen8] = useState(false);
    const [isOpen9, setIsOpen9] = useState(false);
    return(
        <>
        

            <div className="grid-container" id="shop">
                <div className="grid-item">
                    <img src={C1} alt="" />
                    <h2>Bread</h2>
                    <p>Price as per MRP</p>
                    <a href="#shop"><button type="button" id="button-cart" onClick={() => setIsOpen(true)} >Quick View</button></a>
                    <FirstModal open = {isOpen} onClose={() => setIsOpen(false)} >
                        
                    </FirstModal>
                </div>
                <div className="grid-item" id="item-2">
                    <img src={C2} alt="" />
                    <h2>Dairy Products</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-2"><button type="button" id="button-cart" onClick={() => setIsOpen2(true)} >Quick View</button></a>
                    
                    <SecondModal open = {isOpen2} onClose={() => setIsOpen2(false)}>
                        
                    </SecondModal>
                </div>
                <div className="grid-item" id="item-3">
                    <img src={C3} alt="" />
                    <h2>Eggs</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-3"><button type="button" id="button-cart" onClick={() => setIsOpen3(true)} >Quick View</button></a>
                    <ThirdModal open = {isOpen3} onClose={() => setIsOpen3(false)} >
                        
                    </ThirdModal>
                </div>
                <div className="grid-item" id="item-4">
                    <img src={C4} alt="" />
                    <h2>Snacks and Branded Foods</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-4" ><button type="button" id="button-cart" onClick={() => setIsOpen4(true)} >Quick View</button></a>
                    <ForthModal open = {isOpen4} onClose={() => setIsOpen4(false)} >
                        
                    </ForthModal>
                </div>
                <div className="grid-item" id="item-5">
                    <img src={C5} alt="" />
                    <h2>Foodgrains and Oil</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-5"><button type="button" id="button-cart" onClick={() => setIsOpen5(true)} >Quick View</button></a>
                    <FifthModal open = {isOpen5} onClose={() => setIsOpen5(false)} >
                        
                    </FifthModal>
                </div>
                <div className="grid-item" id="item-6">
                    <img src={C6} alt="" />
                    <h2>Spices and Mixes</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-6"><button type="button" id="button-cart" onClick={() => setIsOpen6(true)} >Quick View</button></a>
                    <SixthModal open = {isOpen6} onClose={() => setIsOpen6(false)} >
                        
                    </SixthModal>
                </div>
                <div className="grid-item" id="item-7">
                    <img src={C7} alt="" />
                    <h2>Beauty and Hygiene</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-7" ><button type="button" id="button-cart" onClick={() => setIsOpen7(true)} >Quick View</button></a>
                    <SeventhModal open = {isOpen7} onClose={() => setIsOpen7(false)} >
                        
                    </SeventhModal>
                </div>
                <div className="grid-item" id="item-8">
                    <img src={C8} alt="" />
                    <h2>Cleaning and Household</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-8"><button type="button" id="button-cart" onClick={() => setIsOpen8(true)} >Quick View</button></a>
                    <EightModal open = {isOpen8} onClose={() => setIsOpen8(false)} >
                        
                    </EightModal>
                </div>
                <div className="grid-item" id="item-9">
                    <img src={C9} alt="" />
                    <h2>Fresh fruits and vegetables</h2>
                    <p>Price as per MRP</p>
                    <a href="#item-9"><button type="button" id="button-cart" onClick={() => setIsOpen9(true)} >Quick View</button></a>
                    <NinthModal open = {isOpen9} onClose={() => setIsOpen9(false)} >
                        
                    </NinthModal>
                </div>
            </div>
        
        
        </>
    )
}