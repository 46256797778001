import "./EightModal.css"

import C8 from "../Grid-Items/g8.jpg"

export default function EightModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_8-overlay"></div>
            <div className="modal_8">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_8-info">
                    <img src={C8} alt="" />
                    <p id="big"><h2>Cleaning and Household</h2><br />

                    
                    We care about your laundry and dishes. We got you covered for all detergents, toilet cleaners, floor cleaners and dish washers.<br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}