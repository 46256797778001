import "./FirstModal.css";
import C1 from "../Grid-Items/g1.jpg";

export default function FirstModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal-overlay"></div>
            <div className="modal">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal-info">
                    <img src={C1} alt="" />
                    <p id="big"><h2>Bread</h2><br />

                    
                    All kinds of breads available in all variations and brands. Our best sellers include brown bread and whole wheat bread. <br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}