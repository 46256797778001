import "./ThirdModal.css"

import C3 from "../Grid-Items/g3.jpg"

export default function ThirdModal({open, children, onClose }){
    if (!open) return null

    return(
        <>
            <div className="modal_3-overlay"></div>
            <div className="modal_3">
                <button onClick={onClose}><i className="fa-regular fa-x"></i></button>
                <div className="modal_3-info">
                    <img src={C3} alt="" />
                    <p id="big"><h2>Eggs</h2><br />

                    
                    Harvested from the best poultry farms, we sell the best quality and fresh eggs in-store. <br /><br />
                    Price as per MRP</p>
                    
                    
                </div>
                    
                
                
            {children}
            </div>
        </>
    )
}